import ListModelBase from "@/apps/core/models/listModelBase.js";

const modelName = "staseList";
const modelPath = "/stase/";

class StaseList extends ListModelBase {
  constructor() {
    let filterMap = {
      tahun: null,
      sub_departemen: null
    }
    super(modelName, modelPath, filterMap);
  }
}

export default StaseList;